<template>
  <div class="container">
    <div class="searchBox">
      <el-form :inline="true" :model="dataForm" size="small">
        <el-form-item label="关键词">
          <el-input v-model="dataForm.keyword" placeholder="公告名称/公告内容 模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="公告状态">
          <el-select class="item-choose" v-model="dataForm.status" style="width: 100%;" filterable clearable
            placeholder="公告状态">
            <el-option label="待发布" :value="10"></el-option>
            <el-option label="发布中" :value="20"></el-option>
            <el-option label="发布结束" :value="30"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="getAnnounList()" type="primary">查询</el-button>
          <el-button v-if="isAuth('request:announcement:create')" @click="editOrAddAnnoun({}, 'info')"
            type="success">添加公告</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableBox tenants-list">
      <el-table ref="tableRef" :data="tablelist" border size="mini" v-loading="tableLoading"
        element-loading-text="正在加载中" @row-dblclick="rowClick">
        <el-table-column label="公告标题" :show-overflow-tooltip="true" prop="title" />
        <el-table-column label="是否全平台发布" :show-overflow-tooltip="true" prop="whetherToReleaseItOnAllPlatforms" v-if="isDefaultTenantId" >
          <template slot-scope="scope">
            <el-tag v-if="scope.row.whetherToReleaseItOnAllPlatforms == 0" type="info">否</el-tag>
            <el-tag v-if="scope.row.whetherToReleaseItOnAllPlatforms == 1" type="success">是</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="通知入口" :show-overflow-tooltip="true" prop="notificationEntryName" />
        <el-table-column label="短语" :show-overflow-tooltip="true" prop="phrase" />
        <el-table-column label="发布时间" :show-overflow-tooltip="true" prop="publishTime" />
        <el-table-column label="结束时间" :show-overflow-tooltip="true" prop="endTime" />
        <el-table-column label="发布人" :show-overflow-tooltip="true" prop="createName" />
        <el-table-column label="发布平台" :show-overflow-tooltip="true" prop="tenantName" />
        <el-table-column fixed="right" width="80" label="公告状态" align="center" prop="createTime">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status === 10" type="info">待发布</el-tag>
            <el-tag v-if="scope.row.status === 20" type="warning">发布中</el-tag>
            <el-tag v-if="scope.row.status === 30" type="success">发布结束</el-tag>
            <el-tag v-if="scope.row.status === 40" type="danger">发布中止</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" prop="createTime" align="center">
          <template slot-scope="scope">
            <el-button v-if="isAuth('request:announcement:update')" @click="editOrAddAnnoun(scope.row)" type="text"
              size="mini">编辑</el-button>
            <el-button v-if="isAuth('request:package:deleted')" @click="deleteTenants(scope.row)" type="text"
              size="mini">删除</el-button>
            <el-button @click="viewBulletin(scope.row)" type="text" size="mini">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle" :page-sizes="[25, 50, 100]"
        :page-size="params.pageSize" :total="totalPage" layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
    <AnnounPopup ref="announPopup" @cancel="getAnnounList" />
    <el-drawer :title="dialo.title" :visible.sync="drawer" direction="rtl" :append-to-body="true">
      <div class="drawer_wrap">
        <el-date-picker :value="[dialo.publishTime, dialo.endTime]" readonly type="datetimerange" range-separator="至" start-placeholder="发布时间"
          end-placeholder="结束时间">
        </el-date-picker>
        <div v-html="dialo.content"></div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import AnnounPopup from './components/announ-popup.vue'
export default {
  components: { AnnounPopup },
  data() {
    return {
      params: {
        pageNum: 1,
        pageSize: 25
      },
      totalPage: 0,
      tableLoading: false,
      dataForm: {
        keyword: '',
        status: null
      },
      tablelist: [],
      drawer: false,
      dialo: {},
      isDefaultTenantId: (sessionStorage.getItem('Tenant-Id') || 10000) == 10000,
    }
  },
  created() {
    this.getAnnounList()
  },
  methods: {
    // 获取公告列表
    getAnnounList() {
      this.tableLoading = true
      this.$http({
        url: this.$http.adornUrl('/announcement/getMyList'),
        method: 'get',
        params: this.$http.adornParams({ ...this.params, ...this.dataForm })
      }).then((response) => {
        if (response) {
          this.tablelist = response.data.list
          this.totalPage = response.data.total
          if (this.tablelist != null && this.tablelist.length > 0) {
            this.tablelist.forEach(x => {
              if (x.notificationEntry != null) {
                let notificationEntryName = null
                x.notificationEntry.split(',').forEach(i => {
                  if (i == 0) {
                    notificationEntryName = notificationEntryName == null || notificationEntryName == undefined ? '客户端' : notificationEntryName + ',客户端'
                  }
                  if (i == 1) {
                    notificationEntryName = notificationEntryName == null || notificationEntryName == undefined ? '司机端' : notificationEntryName + ',司机端'
                  }
                  if (i == 2) {
                    notificationEntryName = notificationEntryName == null || notificationEntryName == undefined ? '调度端' : notificationEntryName + ',调度端'
                  }
                })
                x.notificationEntryName = notificationEntryName
              }
            })
          }
        }
        setTimeout(() => {
          this.tableLoading = false
        }, 300)
      })
    },
    // 修改/新增租户套餐
    editOrAddAnnoun(row = {}) {
      this.$refs.announPopup.openDialog(row)
    },
    // 删除公告
    deleteTenants(row) {
      this.$confirm('确定进行【删除】操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl(`/announcement/delete`),
          method: 'delete',
          params: { id: row.id }
        }).then(({ data }) => {
          if (data) {
            this.$message.success('删除成功')
            this.getAnnounList()
          }
        })
      }).catch(() => { })
    },
    // 分配租户套餐菜单
    assignTenantsMenu(row) {
      this.$refs.tenantsPopup.openDialog(row, 'menu')
    },
    // 变更租户套餐状态
    handleSwitchChange(val, row) {
      this.$http({
        url: this.$http.adornUrl(`/announcement/changeStatus`),
        method: 'get',
        params: this.$http.adornParams({
          id: row.id,
          status: val
        })
      }).then(({ data }) => {
        if (data) {
          this.$message.success('已变更')
          this.getAnnounList()
        }
      })
    },
    // 每页数
    sizeChangeHandle(val) {
      this.params.pageSize = val
      this.params.pageNum = 1
      this.getAnnounList()
    },
    // 当前页
    currentChangeHandle(val) {
      this.params.pageNum = val
      this.getAnnounList()
    },
    // 查看公告
    viewBulletin(row = {}) {
      this.dialo = row
      this.drawer = true
    },
    // 点击行
    rowClick(row) {
      this.viewBulletin(row)
    },
  }
}
</script>

<style lang="less" scoped>
::v-deep .el-drawer__body {
  border-top: 1px solid #E4E7ED;
}

.drawer_wrap {
  padding: 10px;
  ::v-deep .el-date-editor{
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
